import React, { useEffect } from "react";
import Footer from "../components/atoms/footer";
import Header from "../components/atoms/header";
import WhyBanner from "../components/ogranisms/WhyBanner";
import HeroBanner from "../components/atoms/HeroBanner";
import WhyReason from "../components/atoms/WhyReason";
import { reasons } from "../constants/whyReasons";
import FreeTrial from "../components/atoms/freeTrial";
import WhySlider from "../components/atoms/WhySlider";
import WhyFirstReason from "../components/atoms/WhyPage/FirstSection";
import WhySecondReason from "../components/atoms/WhyPage/SecondSection";
import Head from "../components/ogranisms/head";
import AOS from "aos";
import "aos/dist/aos.css";
function Index() {
  useEffect(() => {
    AOS.init({
      disable: "mobile",
      duration: 1500,
    });
  }, []);
  return (
    <>
      <Head
        title="Transform the way you communicate today"
        description="Look your professional best. Design like a Pro. Present like a Master. Share the future forward way. Organize the WiZR way. Master your material in a click  WiZR Connect - New video experience  A dream tool in the making   "
        ogTitle="10X"
      />
      <main>
        <Header />
        <WhyBanner />
        <HeroBanner filename="Puting-dialog.jpg" extraClass="whyDialog" >
          <h1>Putting Dialogue in the Center</h1>
          </HeroBanner>
        <WhyFirstReason />
        <WhySecondReason />
        {reasons.map((reason) => (
          <WhyReason
            number={reason.number}
            description={reason.description}
            animation={reason.animation}
            title={reason.title}
            image={reason.image}
            background={reason.background}
            extraClass={reason.extraClass}
            subtitle={reason.subtitle}
            subtitle2={reason.subtitle2}
            subparagraph={reason.subparagraph}
            dataAos={reason.dataAos}
            delay={reason.delay}
          />
        ))}
        <WhySlider />
        <FreeTrial />
        <Footer />
      </main>
    </>
  );
}

export default Index;
