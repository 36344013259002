// extracted by mini-css-extract-plugin
export var animationPart = "WhyReason-module--animationPart--3d29f";
export var blackBackground = "WhyReason-module--blackBackground--6d561";
export var blueBackground = "WhyReason-module--blueBackground--ae551";
export var container = "WhyReason-module--container--38ffd";
export var description = "WhyReason-module--description--27041";
export var eighth = "WhyReason-module--eighth--af02c";
export var fifth = "WhyReason-module--fifth--1c53b";
export var first = "WhyReason-module--first--d7f86";
export var fourth = "WhyReason-module--fourth--b42ee";
export var grayToWhite = "WhyReason-module--grayToWhite--55c20";
export var image = "WhyReason-module--image--17444";
export var innerContainer = "WhyReason-module--innerContainer--580f7";
export var ninth = "WhyReason-module--ninth--f9124";
export var number = "WhyReason-module--number--ec587";
export var second = "WhyReason-module--second--6039d";
export var seventh = "WhyReason-module--seventh--77907";
export var sixth = "WhyReason-module--sixth--d350a";
export var subtext = "WhyReason-module--subtext--a3ec1";
export var tenth = "WhyReason-module--tenth--dd5bd";
export var text = "WhyReason-module--text--d76bc";
export var third = "WhyReason-module--third--1bff6";
export var title = "WhyReason-module--title--1ab25";
export var whiteBackground = "WhyReason-module--whiteBackground--74575";
export var whiteColor = "WhyReason-module--whiteColor--b6967";