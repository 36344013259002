import React from "react";
import { Helmet } from "react-helmet";

const Index = ({ title, description, image, url }) => {

  const parsedContent = description.replace(/<[^>]+>/g, '');

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="title" content={title} />
      <meta name="description" content={parsedContent} />
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta
        property="og:site_name"
        content='MASTERWiZR'
      />
      <meta property="og:description" content={parsedContent} />
      <meta
        property="og:type"
        content= "website"
      />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <meta property="facebook-domain-verification" content="nyo8osoudwn16p9y737kk1t2kmn38e" />
    </Helmet>
    
  );
};

export default Index;
