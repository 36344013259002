import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from "react";

export const PureImage = ({
  filename,
  className = '',
  alt = 'Masterwizr',
  height = '100%',
  width = '100%',
  data,
  }) => {
    const image = data.images.edges.find((n) => n.node.relativePath.includes(filename));
    if (!image) {
        return null;
    }
    const filename_extension = filename.split('.')[1];
    if(filename_extension === 'gif'){
        return <img className={className} style={{ height, width }} alt={alt} src={image.node.publicURL} />
    }
    return (
        <GatsbyImage
            className={className}
            loading="lazy"
            style={{ height, width }}
            alt={alt}
            image={image.node.childImageSharp.gatsbyImageData}
        />
    );
};

export const Image = (props) => (
    <StaticQuery
        query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                gatsbyImageData(
                    layout: FULL_WIDTH,
                    placeholder: NONE
                )
              }
              publicURL
            }
          }
        }
      }
    `}
        render={(data) => <PureImage {...props} data={data} />}
    />
);

