import React from 'react';
import * as styles from './WhySlider.module.scss';
import WhyImage from "../WhyImage";
import whySlider1 from '../../../images/whySlider-1.png';
import whySlider2 from '../../../images/whySlider-2.png';
import whySlider3 from '../../../images/whySlider-3.png';
import whySlider4 from '../../../images/whySlider-4.png';

const WhySlider = () => {
    const images = [
        {image: whySlider1, title:'Design'},
        {image: whySlider2, title:'Present'},
        {image: whySlider3, title:'Share'},
        {image: whySlider4, title:'Collaboration'}
    ];
    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                {/* <p className={styles.number}>10.</p> */}
                <h2 className={styles.title}>All-in-one system</h2>
                <p className={styles.description}>The convenience of everything in one place. Become more efficient with meetings, presentations, and access to your material.</p>
                <div className={styles.slider}>
                    {images.map((image) => {
                        return <div style={{ overflow:'hidden', borderRadius:'3.2rem' }}>
                            <WhyImage image={image.image} title={image.title} />
                        </div>
                    })}
                </div>
            </div>
        </div>
    );
};

export default WhySlider;
