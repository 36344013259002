import React from 'react';
import * as styles from './WhyReason.module.scss';
import {Image} from "../../../hooks/gatsbyImg";
import image1 from '../../../images/master-your-material.png';
const WhyReason = ({number, title, description, image, background,animation, extraClass,subparagraph,subtitle,subtitle2,delay,dataAos}) => {

    return (
        <div className={`${styles.container} ${styles[background]} ${styles[extraClass]}`}>
            <div className={styles.innerContainer}>
                <div className={styles.text}>
                    <p className={styles.number}>{number}.</p>
                    <h3 className={styles.title}>{title}</h3>
                    <p className={styles.description}>{description}</p>
                </div>
                <div>
                {subtitle ? 
                <div className={styles.subtext}>
                    <h2>{subtitle}</h2>
                    <h2>{subtitle2} </h2>
                    <p>{subparagraph}</p>
                </div>
                :null
                }
                {animation ? 
                <div className={styles.animationPart}>
                    <img src={image1}  alt='MasterWizr'/>
                </div> : null
                }
                <div data-aos={dataAos} data-aos-delay={delay}>
                <Image filename={image} className={styles.image} />
                </div>
                </div>
            </div>
        </div>
    );
};

export default WhyReason;
