// extracted by mini-css-extract-plugin
export var background = "SecondSection-module--background--05ca3";
export var container = "SecondSection-module--container--841db";
export var description = "SecondSection-module--description--42de9";
export var image = "SecondSection-module--image--f0acd";
export var innerContainer = "SecondSection-module--innerContainer--c4641";
export var number = "SecondSection-module--number--1da6a";
export var second = "SecondSection-module--second--3fd7e";
export var text = "SecondSection-module--text--d7ec3";
export var title = "SecondSection-module--title--edaaf";
export var topimg = "SecondSection-module--topimg--f338e";