// extracted by mini-css-extract-plugin
export var aboutUs = "HeroBanner-module--aboutUs--8b9d9";
export var aboutUsParallax = "HeroBanner-module--aboutUsParallax--91e7b";
export var aboutUsSecond = "HeroBanner-module--aboutUsSecond--01caa";
export var bannerModules = "HeroBanner-module--bannerModules--7ee67";
export var bannerWizzy = "HeroBanner-module--bannerWizzy--f0a4c";
export var children = "HeroBanner-module--children--5e47f";
export var contactUs = "HeroBanner-module--contactUs--93556";
export var container = "HeroBanner-module--container--b73ae";
export var h3 = "HeroBanner-module--h3--9d03c";
export var hiringPage = "HeroBanner-module--hiringPage--9f312";
export var image = "HeroBanner-module--image--53615";
export var magazineBanner = "HeroBanner-module--magazineBanner--e22da";
export var moduleBanner = "HeroBanner-module--moduleBanner--48fd0";
export var presentationBanner = "HeroBanner-module--presentationBanner--75a95";
export var pricingBaner = "HeroBanner-module--pricingBaner--c4be7";
export var pricingBaner2 = "HeroBanner-module--pricingBaner2--8ed68";
export var pricingBanner1 = "HeroBanner-module--pricingBanner1--3bab3";
export var servicesBanner = "HeroBanner-module--servicesBanner--eb85f";
export var sharingPlatform = "HeroBanner-module--sharingPlatform--ddca2";
export var whyDialog = "HeroBanner-module--whyDialog--87ada";
export var whyPage = "HeroBanner-module--whyPage--5dc48";