import React from 'react';
import * as styles from './SecondSection.module.scss';
import 'react-slideshow-image/dist/styles.css'

const WhySecondReason = () => {
    return (
        <div className={`${styles.container} ${styles.second}`}>
            <div className={styles.innerContainer}>
                <div className={styles.text}>
                    <p className={styles.number}>02.</p>
                    <h3 className={styles.title}>Design like a Pro</h3>
                    <p className={styles.description}>Step into the future of how you create presentations. <br/> A whole new experience. </p>
                </div>
                <div className={styles.background}>
                    <div data-aos="fade-up"
     data-aos-anchor-placement="top-bottom" data-aos-delay='100' style={{ position:'relative' }}>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhySecondReason;