import React from 'react';
import * as styles from './WhyBanner.module.scss';
import HeroBanner from "../../atoms/HeroBanner";

const WhyBanner = () => {
    return (
        <div className={styles.mainwhy}>
            <HeroBanner extraClass="whyPage">
                <h1 data-aos="zoom-in" data-aos-delay="100" className={styles.title}>Transform the way you communicate today!</h1>
                <p data-aos="zoom-in"className={styles.subtitle} data-aos-delay="200">2 weeks free trial on us</p>
                <a
                aria-label="redirect"
                 data-aos="zoom-in" data-aos-delay="300" href="/contact" className={styles.button}>
                    <div className={styles.gray}>
                        <div className={styles.text}>
                            <p></p>
                        </div>
                    </div>
                </a>
            </HeroBanner>
        </div>
    );
};

export default WhyBanner;
