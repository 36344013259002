import React from 'react';
import * as styles from './WhyImage.module.scss';

const WhyImage = ({image, title}) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container} style={{ backgroundImage: `linear-gradient(180deg, #00000017 0%, #000000B5 100%), url(${image})` }}>
            </div>
            <p className={styles.title}>{title}</p>
        </div>
);
};

export default WhyImage;