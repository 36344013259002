import React from "react";
import * as styles from './freeTrial.module.scss';
import StyledButton from "../StyledButton";
import { IoIosArrowForward } from "react-icons/io";
import image from '../../../images/getwizrlogo.png';
const FreeTrial = () => {
    return(
      <div className={styles.container}>
          <div data-aos="zoom-out">
          <img alt="logo"  src={image} className={styles.logo}/>
          </div>
          <p className={styles.description}>Fueling unparalleled collaborations</p>
          <StyledButton 
          title="Try for free today "
          extraClass="graybtn"
          link="https://accounts.masterwizr.com/campaign/campaign_65958a56afc9cb190d926401"
          email={true}
          />
      </div>
    );
}

export default FreeTrial;